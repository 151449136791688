import { fabric } from 'fabric';
import 'magnific-popup';
jQuery( document ).ready( function ( $ ) {
	$('.btn-apple').on('click', function( e ) {
		e.preventDefault();
		$('.popup-apple-music').fadeToggle();
	} );
	$('.close-apple').on('click', function( e ) {
		e.preventDefault();
		$('.popup-apple-music').fadeToggle();
	} );
	// newsletter form
	$(".nl-form").on("submit", function (e) {
		// Prevent default browser actions on form submission.
		e.preventDefault();
		// Get all the form user submitted data and get it ready to be sent.
		var data = $(this).serialize();
		// POST all the data to the SMF submission endpoint URL.
		$.ajax({
			type: "POST",
			url: "https://subs.sonymusicfans.com/submit",
			dataType: "json",
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function (data) {
				// Do things on successful submission.
				$('.step').hide();
                $('.generator-wrap').show();
			},
			error: function (err) {
				// Do things when submission has an error.
				alert("An error has occured!");
			}
		});
	});

	//Apple Form
	$('.btn-apple').on('click', function( e ) {
	
		let AmkPlaylists = [];
		let AmkAlbums = ["1626566926"];
		// Create a new SMEAppleMusic object with your parameters
		let sm = new SMEAppleMusic({
		ae: {
			ae: '50488d79c1615e5ec20a184885ebefeed4677ffa55aeadb8fc28ed406d021082',
			brand_id: '4241115',
			segment_id: '1498468',
			activities: '{"actions":{"presave":90324},"mailing_list_optins":{"a0S1p00000TLJc9EAH":90325,"a0S61000000b376EAA":90326}}'
		},
		am: {
			dev_token: '',
			save_mode: 'library',
			custom_playlist_name: '',
			resources: {
			playlists: AmkPlaylists,
			albums: AmkAlbums
			}
		},
		sf: {
			form: '182799',
			default_mailing_list: ''
		},
		smf: {
			campaign_id: '182799',
			campaign_key: '4052f921a6bee0e8e9b8da37a93bdf4d'
		}
		});
		document.getElementById('apple_music_form').addEventListener('submit', function(e) {
			e.preventDefault();
			// Grab the email address value
			const email = document.getElementById('apple_music_email_address').value;
			// Pass the email address and opt in status
			// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
			sm.doActions(email, false, ["a0S1p00000TLJc9EAH","a0S61000000b376EAA"]).then(function (res) {
				// Complete - handle your logic here
				$('.step').hide();
				$('.generator-wrap').show();
				$('.popup-apple-music').fadeToggle();
			}).catch(function(err) {
				// Error - handle your logic here
			});
		});
	} );

	

	// Canvas setup
	fabric.Object.prototype.set( {
		transparentCorners: false,
		borderColor: '#FFF',
		cornerColor: '#FFF',
		cornerSize: 30,
		cornerStyle: 'circle',
		lockUniScaling: true,
		minScaleLimit: 0.10,
		width: 635,
		height: 635
	} );
	const canvas = new fabric.Canvas( 'canvas', {
		backgroundColor: '#fff',
		backgroundImage: './dist/img/card/card-bg.png',
		enableRetinaScaling: true,
		allowTouchScrolling: false,
		preserveObjectStacking: true,
		selection: false
	} );
	const text_areas = {
		'card_first_name': {
			canvas_text: 'null',
			top: 590,
			left: 430,
			width: 300
		},
	};
	bindText();
	function bindText() {
		for ( let k in text_areas ) {
			text_areas[k].canvas_text = new fabric.Textbox( 'from First Name' + $( '#' + k ).val(), {
				top: text_areas[k].top,
				left: text_areas[k].left,
				width: text_areas[k].width,
				breakWords: true,
				originX: 'center',
				originY: 'center',
				textAlign: 'left',
				fontSize: 28,
				lineHeight: 1,
				fontFamily: 'adobe-handwriting-ernie',
				fontWeight: 'normal',
				fill: '#bb914c',
				lockRotation: true,
				lockMovementX: true,
				lockMovementY: true,
				lockScalingX: true,
				lockScalingY: true,
				splitByGrapheme: true,
				transparentCorners: true,
				cornerSize: 0,
				border: 0,
				autoCapitalize: 'on',
				asBorders: false,
				strokeWidth: 0,
				editable: false,
			} );
			canvas.add( text_areas[k].canvas_text );
			text_areas[k].canvas_text.bringToFront();
		}
		canvas.renderAll.bind( canvas );
	}
	fabric.Image.fromURL( './dist/img/card/card.png', function ( img ) {
		img.set( {
			left: 0,
			top: 0,
			angle: 0,
			scaleX: canvas.width / img.width,
			scaleY: canvas.height / img.height,
			evented: false,
			selectable: false
		} );
		canvas.add( img );
		img.sendToBack();
	} );
	
	resizeCanvases();
	$( window ).on( 'resize', resizeCanvases );
	function resizeCanvases() {
		const parent_width = $( '.canvas-container-overflow' ).parent().width();
		const canvas_width = $( '.canvas-container' ).outerWidth();
		const canvas_height = $( '.canvas-container' ).outerHeight();
		if ( canvas_width > parent_width ) {
			const width_diff = canvas_width - parent_width;
			const scale_diff = width_diff / canvas_width;
			const scale_inverse = 1 - scale_diff;
			$( '.canvas-container' ).css( 'transform', 'scale3d(' + scale_inverse + ', ' + scale_inverse + ', ' + scale_inverse + ')' );
			$( '.canvas-container-overflow' ).css( 'height', canvas_height * scale_inverse );
		} else {
			$( '.canvas-container' ).css( 'transform', 'scale3d(1, 1, 1)' );
			$( '.canvas-container-overflow' ).css( 'height', 'auto' );
		}
	}
	// Upload
	$( '#image' ).on( 'change', function ( e ) {
		const file = e.target.files[0];
		if ( file.type.match( '/image.*/' ) ) {
			alert( 'Error: This file is not an image!' );
			return;
		}
		if ( file.size > 5242880 ) {
			alert( 'Error: Image is greater than 5MB!' );
			return;
		}
		const file_reader = new FileReader();
		file_reader.readAsDataURL( file );
		file_reader.onabort = function ( e ) {
			alert( 'Error: File read aborted!' );
			$( '#load' ).removeClass( 'loading' );
		};
		file_reader.onerror = function ( e ) {
			alert( 'Error: File could not be uploaded!' );
			$( '#load' ).removeClass( 'loading' );
		};
		file_reader.onloadstart = function ( e ) {
			$( '#load' ).addClass( 'loading' );
		};
		file_reader.onload = function ( e ) {
			canvas.clear();
			canvas.setBackgroundColor( '#fff' );
			fabric.Image.fromURL( './dist/img/card/card.png', function ( img ) {
				img.set( {
					left: 0,
					top: 0,
					angle: 0,
					scaleX: canvas.width / img.width,
					scaleY: canvas.height / img.height,
					evented: false,
					selectable: false
				} );
				canvas.add( img );
				img.sendToBack();
				fabric.Image.fromURL( e.target.result, function ( img ) {
					const max_height = 330;
					img.scaleToHeight( max_height );
					img.set( {
						left: 210 - (
							img.getScaledWidth() / 2
						),
						top: 238,
						angle: 0
					} );
					canvas.add( img );
					img.sendToBack();
					canvas.setActiveObject( img );
				} );
			} );
            
			resizeCanvases();
            bindText();
			canvas.renderAll.bind( canvas );
		};
	} );
	// Download
	$( '#btn-save' ).on( 'click', function ( e ) {
		e.preventDefault();
		const btn = $( this );
		const nonce = btn.attr( 'data-nonce' );
		const img = canvas.toDataURL( { format: 'jpeg', quality: 0.8 } );
		const a = document.createElement( 'a' );
        a.href = img;
        a.download = 'card.jpeg';
        a.click();
        a.remove();
	} );
	$( '.live-text' ).on( 'keyup', function ( e ) {
		e.preventDefault();
		const val = $( this ).val();
		const id = $( this ).attr( 'id' );
		text_areas[id].canvas_text.set( 'text', 'from ' + val );
        // bindText();
		canvas.renderAll();
	} );

    
    $('.generator-wrap').hide(); 

    let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function ( callback, form_id ) {
			let hash;
			let hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
			for ( let i = 0; i < hashes.length; i ++ ) {
				hash = hashes[i].split( '=' );
				this.url_vars.push( hash[0] );
				this.url_vars[hash[0]] = hash[1];
			}
			this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
			this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
			this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
			this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
			this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
			if ( typeof callback === 'function' ) {
				callback( this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message );
			}
		}
	};

	/* String state
	  *  The status of the pre-save.
	  *  Int ae_member_id
	  *  The Appreciation Engine member ID. This can be passed to the forms processor.
	  *  String spotify_user
	  *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
	  *  String deezery_user
	  *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
	  */
	SMEPreSave.getURLVars( function ( state, ae_member_id, spotify_user, deezer_user, message ) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if ( state === 'success' || state === 'thank-you' ) {
            $('.step').hide();
            $('.generator-wrap').show();
		}
	} );
} );